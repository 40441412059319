import { Avatar, Tooltip } from "@mui/material"
import cpp from "./Images/cpp.png"
import c from "./Images/c.png"
import csharp from "./Images/csharp.png"
import python from "./Images/python.png"
import java from "./Images/java.png"
import kotlin from "./Images/kotlin.png"
import javascript from "./Images/javascript.png"
import matlab from "./Images/matlab.png"
import docker from "./Images/docker.png"
import kubernetes from "./Images/kubernetes.png"
import reactjs from "./Images/reactjs.png"
import git from "./Images/git.png"
import nodejs from "./Images/nodejs.png"
import arduino from "./Images/arduino.png"
import qtcreator from "./Images/qtcreator.png"
import nginx from "./Images/nginx.png"
import postgresql from "./Images/postgresql.png"
import rest from "./Images/rest.png"
import aws from "./Images/aws.png"
import android from "./Images/android.png"
import React from "react"

export const CppIcon = () => {
  return (
    <Tooltip title="C++" placement="bottom" disableInteractive>
      <Avatar src={cpp} />
    </Tooltip>
  )
}

export const CIcon = () => {
  return (
    <Tooltip title="C" placement="bottom" disableInteractive>
      <Avatar src={c} />
    </Tooltip>
  )
}

export const CSharpIcon = () => {
  return (
    <Tooltip title="C#" placement="bottom" disableInteractive>
      <Avatar src={csharp} variant="square" />
    </Tooltip>
  )
}

export const PythonIcon = () => {
  return (
    <Tooltip title="Python" placement="bottom" disableInteractive>
      <Avatar src={python} variant="square" />
    </Tooltip>
  )
}

export const JavaIcon = () => {
  return (
    <Tooltip title="Java" placement="bottom" disableInteractive>
      <Avatar src={java} variant="square" />
    </Tooltip>
  )
}

export const KotlinIcon = () => {
  return (
    <Tooltip title="Kotlin" placement="bottom" disableInteractive>
      <Avatar src={kotlin} variant="square" />
    </Tooltip>
  )
}

export const JavascriptIcon = () => {
  return (
    <Tooltip title="Javascript" placement="bottom" disableInteractive>
      <Avatar src={javascript} variant="square" />
    </Tooltip>
  )
}

export const MatlabIcon = () => {
  return (
    <Tooltip title="MATLAB" placement="bottom" disableInteractive>
      <Avatar src={matlab} variant="square" />
    </Tooltip>
  )
}

export const DockerIcon = () => {
  return (
    <Tooltip title="Docker" placement="bottom" disableInteractive>
      <Avatar src={docker} variant="square" />
    </Tooltip>
  )
}

export const KubernetesIcon = () => {
  return (
    <Tooltip title="Kubernetes" placement="bottom" disableInteractive>
      <Avatar src={kubernetes} variant="square" />
    </Tooltip>
  )
}

export const ReactJSIcon = () => {
  return (
    <Tooltip title="ReactJS" placement="bottom" disableInteractive>
      <Avatar src={reactjs} variant="square" />
    </Tooltip>
  )
}

export const GitIcon = () => {
  return (
    <Tooltip title="Git" placement="bottom" disableInteractive>
      <Avatar src={git} variant="square" />
    </Tooltip>
  )
}

export const NodeJSIcon = () => {
  return (
    <Tooltip title="NodeJS" placement="bottom" disableInteractive>
      <Avatar src={nodejs} />
    </Tooltip>
  )
}

export const ArduinoIcon = () => {
  return (
    <Tooltip title="Arduino" placement="bottom" disableInteractive>
      <Avatar src={arduino} variant="square" />
    </Tooltip>
  )
}

export const QtCreatorIcon = () => {
  return (
    <Tooltip title="QtCreator" placement="bottom" disableInteractive>
      <Avatar src={qtcreator} variant="square" />
    </Tooltip>
  )
}

export const NginxIcon = () => {
  return (
    <Tooltip title="NGINX" placement="bottom" disableInteractive>
      <Avatar src={nginx} variant="square" />
    </Tooltip>
  )
}

export const PostgreSQLIcon = () => {
  return (
    <Tooltip title="PostgreSQL" placement="bottom" disableInteractive>
      <Avatar src={postgresql} variant="square" />
    </Tooltip>
  )
}

export const RESTIcon = () => {
  return (
    <Tooltip title="RESTful API" placement="bottom" disableInteractive>
      <Avatar src={rest} />
    </Tooltip>
  )
}

export const AWSIcon = () => {
  return (
    <Tooltip title="AWS Cloud" placement="bottom" disableInteractive>
      <Avatar src={aws} />
    </Tooltip>
  )
}

export const AndroidIcon = () => {
  return (
    <Tooltip title="Android" placement="bottom" disableInteractive>
      <Avatar src={android} />
    </Tooltip>
  )
}

export const ICON_INFO = {
  "C++": CppIcon,
  C: CIcon,
  "C#": CSharpIcon,
  Python: PythonIcon,
  Java: JavaIcon,
  Kotlin: KotlinIcon,
  Javascript: JavascriptIcon,
  MATLAB: MatlabIcon,
  Docker: DockerIcon,
  Kubernetes: KubernetesIcon,
  ReactJS: ReactJSIcon,
  Git: GitIcon,
  NodeJS: NodeJSIcon,
  Arduino: ArduinoIcon,
  QtCreator: QtCreatorIcon,
  NGINX: NginxIcon,
  PostgreSQL: PostgreSQLIcon,
  REST: RESTIcon,
  AWS: AWSIcon,
  Android: AndroidIcon,
}
