import {
  Button,
  Card,
  CardContent,
  Divider,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material"
import { Box, Stack } from "@mui/system"
import React from "react"
import Scale from "../Components/Scale"
import { PROJECTS } from "../Constants/Projects"
import { ICON_INFO } from "../Constants/TechnologyIcons/Icons"
import { useStateContext } from "../ContextProvider"

//out of 7
const skills = [
  {
    name: "C++",
    skillLevel: 6,
    projects: PROJECTS.filter((project) =>
      project.technologies.includes(ICON_INFO["C++"])
    ),
  },
  {
    name: "C",
    skillLevel: 4,
    projects: PROJECTS.filter((project) =>
      project.technologies.includes(ICON_INFO["C"])
    ),
  },
  {
    name: "C#",
    skillLevel: 5,
    projects: PROJECTS.filter((project) =>
      project.technologies.includes(ICON_INFO["C#"])
    ),
  },
  {
    name: "Python",
    skillLevel: 4,
    projects: PROJECTS.filter((project) =>
      project.technologies.includes(ICON_INFO["Python"])
    ),
  },
  {
    name: "Java",
    skillLevel: 5,
    projects: PROJECTS.filter((project) =>
      project.technologies.includes(ICON_INFO["Java"])
    ),
  },
  {
    name: "Kotlin",
    skillLevel: 3,
    projects: PROJECTS.filter((project) =>
      project.technologies.includes(ICON_INFO["Kotlin"])
    ),
  },
  {
    name: "Javascript",
    skillLevel: 5,
    projects: PROJECTS.filter((project) =>
      project.technologies.includes(ICON_INFO["Javascript"])
    ),
  },
  {
    name: "MATLAB",
    skillLevel: 3,
    projects: PROJECTS.filter((project) =>
      project.technologies.includes(ICON_INFO["MATLAB"])
    ),
  },
  {
    name: "Docker",
    skillLevel: 4,
    projects: PROJECTS.filter((project) =>
      project.technologies.includes(ICON_INFO["Docker"])
    ),
  },
  {
    name: "Kubernetes",
    skillLevel: 3,
    projects: PROJECTS.filter((project) =>
      project.technologies.includes(ICON_INFO["Kubernetes"])
    ),
  },
  {
    name: "ReactJS",
    skillLevel: 5,
    projects: PROJECTS.filter((project) =>
      project.technologies.includes(ICON_INFO["ReactJS"])
    ),
  },
  {
    name: "Git",
    skillLevel: 5,
    projects: PROJECTS.filter((project) =>
      project.technologies.includes(ICON_INFO["Git"])
    ),
  },
  {
    name: "NodeJS",
    skillLevel: 4,
    projects: PROJECTS.filter((project) =>
      project.technologies.includes(ICON_INFO["NodeJS"])
    ),
  },
  {
    name: "Arduino",
    skillLevel: 4,
    projects: PROJECTS.filter((project) =>
      project.technologies.includes(ICON_INFO["Arduino"])
    ),
  },
  {
    name: "QtCreator",
    skillLevel: 4,
    projects: PROJECTS.filter((project) =>
      project.technologies.includes(ICON_INFO["QtCreator"])
    ),
  },
  // {
  //   name: "NGINX",
  //   skillLevel: 3,
  //   projects: PROJECTS.filter((project) =>
  //     project.technologies.includes(ICON_INFO["NGINX"])
  //   ),
  // },
  {
    name: "PostgreSQL",
    skillLevel: 4,
    projects: PROJECTS.filter((project) =>
      project.technologies.includes(ICON_INFO["PostgreSQL"])
    ),
  },
  {
    name: "REST",
    skillLevel: 4,
    projects: PROJECTS.filter((project) =>
      project.technologies.includes(ICON_INFO["REST"])
    ),
  },
  {
    name: "AWS",
    skillLevel: 5,
    projects: PROJECTS.filter((project) =>
      project.technologies.includes(ICON_INFO["AWS"])
    ),
  },
  {
    name: "Android",
    skillLevel: 4,
    projects: PROJECTS.filter((project) =>
      project.technologies.includes(ICON_INFO["Android"])
    ),
  },
]

const CARD_WIDTH = 410

const Skills = (props) => {
  const theme = useTheme()
  const { darkMode, setSelectedItem, isMobile } = useStateContext()
  return (
    <div>
      <Divider sx={{ mb: 2 }} />
      <Typography variant="h4">Skills</Typography>
      <Divider />
      <Stack direction="row" sx={{ flexWrap: "wrap" }} justifyContent="center">
        {skills.map((skill) => {
          const Icon = ICON_INFO[skill.name]
          return (
            <Card
              key={skill.name}
              variant="outlined"
              sx={{ width: CARD_WIDTH, height: isMobile ? "auto" : 275 }}
            >
              <CardContent>
                <Box sx={{ display: "flex", mb: 1.5 }}>
                  <Icon />
                  <Typography variant="h5" component="div" sx={{ ml: 2 }}>
                    {skill.name}
                  </Typography>
                </Box>

                <Scale
                  numPoints={6}
                  rating={skill.skillLevel}
                  color={
                    darkMode
                      ? theme.palette.secondary.main
                      : theme.palette.primary.dark
                  }
                />
                {skill.projects.length ? (
                  <Typography variant="h6" sx={{ mt: 1.5 }}>
                    Projects
                  </Typography>
                ) : (
                  <></>
                )}
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {skill.projects.map((project, idx) => (
                    <Button
                      size="small"
                      key={idx}
                      sx={{
                        justifyContent: isMobile ? "center" : "flex-start",
                      }}
                      onClick={() => {
                        setSelectedItem(project)
                        props.onProjectClick(project.id - 1)
                      }}
                    >
                      <Tooltip
                        title="Click to jump to project"
                        disableInteractive
                      >
                        <Typography
                          sx={{
                            textDecoration: "underline",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: CARD_WIDTH, // Adjust width as needed
                            display: "block",
                          }}
                          variant="body2"
                          color="text.secondary"
                        >
                          {project.title}
                        </Typography>
                      </Tooltip>
                    </Button>
                  ))}
                </div>
              </CardContent>
            </Card>
          )
        })}
      </Stack>
    </div>
  )
}

export default Skills
