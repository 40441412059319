import { Box, Grid, Typography } from "@mui/material"
import {
  CIcon,
  CppIcon,
  CSharpIcon,
  MatlabIcon,
  PythonIcon,
  ArduinoIcon,
  QtCreatorIcon,
  JavaIcon,
  KotlinIcon,
  ReactJSIcon,
  DockerIcon,
  KubernetesIcon,
  //NginxIcon,
  JavascriptIcon,
  GitIcon,
  NodeJSIcon,
  PostgreSQLIcon,
  RESTIcon,
  AWSIcon,
  AndroidIcon,
} from "./TechnologyIcons/Icons"
// import financialityPreview from "./Screenshots/financialityPreview.png"
import PhotoShareApp from "./Screenshots/PhotoShareApp.jpg"
import choreDiscordBot from "./Screenshots/choreDiscordBot.png"

const projects = [
  {
    title: "Desktop App Development in C++ for Acoustic Computations",
    organization: "NAWCAD",
    startDate: "2019-08-15T00:00:00+00:00",
    endDate: "2023-04-15T00:00:00+00:00",
    position: "Computer Scientist",
    role: "Co-Developer",
    summary: [
      "Increased acoustic processing throughput by over 29x by parallelizing code on network nodes",
      "Won a first-place award out of 149 teams for demonstrating outstanding Rapid Prototyping Capabilities",
      "Worked with clients to quickly implement 20+ feature requests, enhancing user experience and satisfaction",
    ],
    technologies: [CppIcon, QtCreatorIcon],
    additionalInfo: null,
  },
  {
    title:
      "Team Lead of 6 Engineers for Acoustic Health Monitoring Innovation Challenge",
    organization: "NAWCAD",
    startDate: "2019-08-15T00:00:00+00:00",
    endDate: "2023-04-15T00:00:00+00:00",
    position: "Computer Scientist",
    role: "Team Lead",
    summary: [
      "Trained a machine learning classifier with acoustic data to predict mechanical faults with 98% accuracy",
      "Optimized C-based processing to reduce hardware latency by 90% compared to the MATLAB interface",
      "Led biweekly team meetings and delivered the final out-brief presentation to the Admiral",
    ],
    technologies: [CIcon, MatlabIcon, PythonIcon],
    additionalInfo: null,
  },
  {
    title: "Acoustic Software Development for P-8 Missions",
    organization: "Eagle Systems Inc",
    startDate: "2023-05-15T00:00:00+00:00",
    endDate: "2025-03-15T00:00:00+00:00",
    position: "Junior Acquisition Support",
    role: "Team Member",
    summary: [
      "Rapidly prototyped features with user feedback, delivering code in 20% of prime contractor’s typical time",
      "Developed a C# network client supporting 200+ communication patterns with the P-8 server",
      "Used Java to implement new data displays for presenting real time acoustic data from the ocean",
      "Recognized for outstanding contributions to the program office and exemplary fleet support",
    ],
    technologies: [
      CSharpIcon,
      JavaIcon,
      PythonIcon,
      CppIcon,
      DockerIcon,
      KubernetesIcon,
      GitIcon,
    ],
    additionalInfo: null,
  },
  {
    title: "Library Development in C++ for Dynamic Load Balancing",
    organization: "NAWCAD",
    startDate: "2017-06-15T00:00:00+00:00",
    endDate: "2019-08-15T00:00:00+00:00",
    position: "Computer Science Intern",
    role: "Team Member",
    summary: [
      "Utilized Microsoft’s Message Passing Interface to distribute workloads across a 16-node network",
      "Implemented advanced mathematical methods to interpolate sound speed vectors in the water",
    ],
    technologies: [CppIcon],
    additionalInfo: null,
  },
  {
    title: "Script Development in MATLAB for Processing Flight Data",
    organization: "ASEC Inc",
    startDate: "2016-06-15T00:00:00+00:00",
    endDate: "2017-05-15T00:00:00+00:00",
    position: "Computer Science Intern",
    role: "Team Member",
    summary: [
      "Developed data visualization scripts and trained six engineers, speeding up delivery of quick-look reports",
    ],
    technologies: [MatlabIcon],
    additionalInfo: null,
  },
  {
    title: "Christmas Tree Light Show",
    organization: "Self",
    startDate: "2021-12-15T00:00:00+00:00",
    endDate: "Present",
    position: "Software Engineer",
    role: "Sole Developer",
    summary: [
      "Created C++ software to control Christmas tree lights, supporting complex lighting patterns",
      "Wrote a python script to map LED positions from video to a 3D coordinate system",
    ],
    technologies: [CIcon, ArduinoIcon, PythonIcon],
    additionalInfo: (
      <Grid container spacing={8} sx={{ overflowX: "auto" }}>
        <Grid item xs="auto">
          <Typography variant="body2" color="text.secondary">
            [Redirect/Ad Blockers may affect video loading]
          </Typography>
          <iframe
            src="https://drive.google.com/file/d/1DOvswkXKZCHv3prhPG4O3TdwQ1ZMNISt/preview"
            width="400"
            height="480"
            allow="autoplay"
            title="Horizontal Strips"
          ></iframe>
          <Box maxWidth={400}>
            <Typography variant="body2" sx={{ mb: 3 }}>
              A video demonstrating how the lights can be controlled
              horizontally (not just along the strips) since they were mapped in
              3D space.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs="auto">
          <iframe
            src="https://drive.google.com/file/d/1DMslali6gWPE9viVJDFVhWrItp7HhfOZ/preview"
            width="400"
            height="480"
            allow="autoplay"
            title="Spherical"
          ></iframe>
          <Box maxWidth={400}>
            <Typography variant="body2">
              A video demonstrating the spherical function, where the lights
              radiate inward (or outward depending on your interpretation)
              toward the origin.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    ),
  },
  {
    title: "Home Automation",
    organization: "Self",
    startDate: "2018-05-15T00:00:00+00:00",
    endDate: "Present",
    position: "Full Stack Software/Hardware Engineer",
    role: "Co-Developer",
    summary: [
      "Developed a full stack chore assignment application, complete with Discord and smart mirror integration",
      "Programmed a Raspberry Pi to remotely control lights, display weather, and show real-time stock data",
    ],
    technologies: [
      CIcon,
      PythonIcon,
      JavascriptIcon,
      ReactJSIcon,
      ArduinoIcon,
      NodeJSIcon,
      PostgreSQLIcon,
      RESTIcon,
    ],
    additionalInfo: (
      <Grid container sx={{ overflowX: "auto" }}>
        <Grid item xs="auto">
          <img src={choreDiscordBot} width={600} alt=""></img>
          <Box maxWidth={600}>
            <Typography variant="body2">
              Shows discord bot sending notifications for a chore (past due in
              this case) that can be reacted to in order to complete
            </Typography>
          </Box>
        </Grid>
      </Grid>
    ),
  },
  {
    title: "Photoshare App (In Development)",
    organization: "Self",
    startDate: "2024-06-15T00:00:00+00:00",
    endDate: "Present",
    position: "Full Stack Engineer",
    role: "Sole Developer",
    summary: [
      "Cloud-based temporary media storage for group trips, allowing users to upload, browse, and download shared photos/videos",
      "AWS-powered backend with Lambda, API Gateway, Cognito, DynamoDB, and S3, ensuring secure authentication, scalable storage, and low-cost operations",
      "Android app integration for seamless media uploads, group management, and file retrieval with automatic cleanup after group period",
      "Affordable pay-per-group model, making it an efficient and cost-effective solution for temporary media sharing",
    ],
    technologies: [
      AWSIcon,
      RESTIcon,
      NodeJSIcon,
      PostgreSQLIcon,
      AndroidIcon,
      KotlinIcon,
      GitIcon,
    ],
    additionalInfo: (
      <Grid container sx={{ overflowX: "auto" }}>
        <Grid item xs="auto">
          <img src={PhotoShareApp} width={300} alt=""></img>
          <Box maxWidth={300}>
            <Typography variant="body2">
              Shows sample group that has photos from a trip to Hawaii, with all
              full images being stored remotely on S3
            </Typography>
          </Box>
        </Grid>
      </Grid>
    ),
  },
  {
    title: "Personal Website",
    organization: "Self",
    startDate: "2022-11-15T00:00:00+00:00",
    endDate: "Present",
    position: "Full Stack Engineer",
    role: "Sole Developer",
    summary: [
      "Used ReactJS to build a website for displaying information about my career",
      // "Self hosted the website on my personal server, using Docker and NGINX as a reverse proxy",
      // "(Look around, it's the page you are on)",
    ],
    technologies: [ReactJSIcon, JavascriptIcon],
    additionalInfo: null,
  },
  // {
  //   title: "Grocery App Meal Planning",
  //   organization: "Self",
  //   startDate: "2023-03-15T00:00:00+00:00",
  //   endDate: "Present",
  //   position: "Full Stack Engineer",
  //   role: "Sole Developer",
  //   summary: [
  //     "Creating a full stack cloud based application for meal planning and nutrition tracking",
  //     "Uses AWS cloud services to host both user data and recipe data",
  //     "Android front end application for accessing the api and easily managing meals [coming soon]",
  //   ],
  //   technologies: [
  //     AWSIcon,
  //     JavascriptIcon,
  //     PostgreSQLIcon,
  //     RESTIcon,
  //     AndroidIcon,
  //   ],
  // },
  // {
  //   title: "Financiality (Finance Split Site)",
  //   organization: "Self",
  //   startDate: "2022-07-15T00:00:00+00:00",
  //   endDate: "Present",
  //   position: "Full Stack Engineer",
  //   role: "Sole Developer",
  //   summary: [
  //     "Creating a full stack application for managing finances and split purchases with a group",
  //     "Enables complex split arrangments, allows for permission control, option to add interest",
  //     "[Work in progress]",
  //   ],
  //   technologies: [
  //     ReactJSIcon,
  //     NodeJSIcon,
  //     JavascriptIcon,
  //     DockerIcon,
  //     PostgreSQLIcon,
  //     RESTIcon,
  //   ],
  //   additionalInfo: (
  //     <Grid container sx={{ overflowX: "auto" }}>
  //       <Grid item xs="auto">
  //         <img src={financialityPreview} width={600} alt=""></img>
  //         <Box maxWidth={600}>
  //           <Typography variant="body2">
  //             Shows sample plotted data queried from the backend api, connected
  //             to a PostgreSQL database.
  //           </Typography>
  //         </Box>
  //       </Grid>
  //     </Grid>
  //   ),
  // },
]

export const PROJECTS = projects.map((project, idx) => {
  project.id = idx + 1
  return project
})
